import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module-auth'
import message from './module-message'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        message
    }
})

<template>
    <dashboard-layout>
        hello
    </dashboard-layout>
</template>

<script>
export default {
    name: 'Home',
    components: {
    }
}
</script>

<template>
    <v-app>
        <v-app-bar app dense clipped-left color="yellow darken-2">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>My Astro Life</v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
        </v-app-bar>

        <v-navigation-drawer
            permanent
            app
            clipped
            dark
        >
            <template v-slot:prepend>
                <v-list-item two-line>
                    <v-list-item-avatar color="primary">
                        <!-- <img src="https://randomuser.me/api/portraits/women/81.jpg"> -->
                        <span class="white--text text-h6">AD</span>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                        <v-list-item-subtitle>Logged In</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item
                    v-for="item in nav"
                    :key="item.text"
                    :to="item.to"
                    link
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Uitloggen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-container>
                <slot></slot>
            </v-container>
        </v-main>
        <v-snackbar
            :value="true"
            v-for="(toast, toastIdx) in toasts" :key="toastIdx"
            transition="fade-transition"
            app
            top
            right
            :color="toast.color"
            :timeout="toast.timeout"
            @input="$store.dispatch('message/removeToast', toast)"
        >{{toast.text}}</v-snackbar>
        <v-dialog :value="true" v-if="appError" max-width="900px">
            <v-card>
                <v-card-title class="error--text">Applicatiefout!</v-card-title>
                <v-card-text>
                    Geef de volgende informatie door aan de ontwikkelaar:
                    <v-textarea readonly :value="appError" @focus="$event.target.select()" rows="15"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="downloadError" color="secondary"><v-icon>mdi-download</v-icon> Download als bestand</v-btn>
                    <v-btn text @click="$store.dispatch('message/clearAppError')" color="primary">Sluiten</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>


</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DashboardLayout.vue",
    computed: {
        ...mapGetters({
            user: 'auth/user',
            toasts: 'message/toasts',
            messages: 'message/messages',
            appError: 'message/appError'
        })
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        }),
        downloadError() {
            this.download(this.appError, "trace-" + (new Date()).toLocaleDateString() + "-" + (+new Date()) + ".txt");
        },
        download(text, filename) {
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    },
    data() {
        return {
            nav: [
                {icon: 'mdi-account-multiple', text: 'Gebruikers', to: {name: 'UserOverview'}}
            ]
        }
    }
}
</script>

<style scoped>

</style>

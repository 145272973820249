export default {
    addToast(state, toast) {
        let addToast = {
            text: "",
            timeout: 5000,
            color: null,
            _: Math.random()
        };
        if (typeof toast === "string")
            addToast.text = toast;
        else
            addToast = {...addToast, ...toast};
        state.toasts.push(addToast);
    },
    popToast(state) {
        state.toasts.pop();
    },
    removeToast(state, toast) {
        state.toasts = state.toasts.filter(x => x !== toast);
    },
    clearToasts(state) {
        state.toasts = [];
    },
    addMessage(state, message) {
        let addMessage = {
            text: "",
            color: 'info',
            _: Math.random()
        }
        if (typeof message === "string")
            addMessage.text = message;
        else
            addMessage = {...addMessage, ...message};
        state.messages.push(addMessage);
    },
    removeMessage(state, messageIdx) {
        state.messages.splice(messageIdx, 1);
    },
    clearMessages(state) {
        state.messages = [];
    },
    setAppError(state, err) {
        state.appError = err;
    },
    clearAppError(state) {
        state.appError = null;
    }
}

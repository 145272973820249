"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '@/store';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
    baseURL: '/api'
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
      let data = error.response.data;
      if (data.message && data.errors && error.response.status === 422) {
        for (let x in data.errors) {
            store.dispatch('message/showMessage', {
                text: data.errors[x][0],
                color: 'error'
            });
        }
      }
      if (error.response.status === 500) {
          /*store.dispatch('message/showToast', {
              text: "Onbekende serverfout opgetreden.",
              color: 'error'
          });*/
          let header = error.response.config.headers;
          if (header.Authorization)
              header.Authorization = header.Authorization.split(" ")[0] + " <<stripped>>";
          store.dispatch('message/setAppError', JSON.stringify({
              url: error.response.config.url,
              method: error.response.config.method,
              data: error.response.config.data,
              headers: error.response.config.headers,
              status: error.response.status,
              trace: error.response.data
          }, null, 2));
      }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
export const http = _axios;

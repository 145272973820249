import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            auth: true
        },
        component: Home
    },
    {
        path: '/users/new',
        name: 'NewUser',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/User/Crud'),
    },
    {
        path: '/users',
        name: 'UserOverview',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/User/Overview'),
    },
    {
        path: '/users/:id',
        name: 'EditUser',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/User/Crud'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }
    next()
})

export default router

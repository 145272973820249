import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import DashboardLayout from "./layouts/DashboardLayout";

Vue.config.productionTip = false

Vue.component('dashboard-layout', DashboardLayout);

new Vue({
  router,
  vuetify,
  store,
    created () {
        const userInfo = localStorage.getItem('user')
        if (userInfo) {
            const userData = JSON.parse(userInfo)
            this.$store.commit('auth/setUserData', userData)
        }
        window.axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    this.$store.dispatch('auth/logout')
                }
                return Promise.reject(error)
            }
        )
    },
  render: h => h(App)
}).$mount('#app')

export default {
    showToast({commit}, toast) {
        commit('addToast', toast);
    },
    removeToast({commit}, toast) {
        commit('removeToast', toast);
    },
    showMessage({commit}, message) {
        commit('addMessage', message);
        if (message.timeout) {
            window.setTimeout(() => {
                commit('popMessage');
            }, message.timeout);
        }
    },
    removeMessage({commit}, message) {
        commit('removeMessage', message);
    },
    clearMessages({commit}) {
        commit('clearMessages');
    },
    setAppError({commit}, error) {
        commit('setAppError', error);
    },
    clearAppError({commit}) {
        commit('clearAppError');
    }
}
